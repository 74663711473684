<template>
  <div class="reactions-comp">
    <div class="reactions-comp__description">
      Выберите орган, чтобы узнать побочное явление
    </div>
    <div class="reactions-comp__tip d-xl-none">
      Для оценки частоты случаев применяли следующие критерии: очень часто
      (>10%), часто (1-9.9%), нечасто (0.1-0.9%), редко (0.01-0.09%), очень
      редко (&lt;0.01%).<sup>2</sup>
    </div>
    <div class="reactions-comp__container">
      <div class="reactions-comp__row">
        <div
          class="reactions-comp__icon"
          @click="currentBlock = i"
          v-for="i in 12"
          :key="i"
        >
          <img
            class="reactions-comp__icon_inactive"
            :src="
              require(`@/assets/img/betalock-landing/reaction-icon${i}.svg`)
            "
          />
          <img
            v-show="i === currentBlock"
            class="reactions-comp__icon_active"
            :src="
              require(`@/assets/img/betalock-landing/reaction-icon${i}_active.svg`)
            "
          />
        </div>
      </div>
      <div class="reactions-comp__body">
        <div class="reactions-comp__text-block">
          <div
            class="reactions-comp__text-item"
            v-for="(block, ind) in blocks[currentBlock - 1]"
            :key="ind"
          >
            <div
              class="reactions-comp__text-item-title"
              v-html="block.title"
            ></div>
            <div
              class="reactions-comp__text-item-description"
              v-html="block.description"
            ></div>
          </div>
        </div>
        <div class="reactions-comp__img">
          <img
            :src="
              require(`@/assets/img/betalock-landing/reaction${currentBlock}.svg`)
            "
          />
        </div>
        <div class="reactions-comp__tip d-none d-xl-block">
          Для оценки частоты случаев применяли следующие критерии: очень часто
          (>10%), часто (1-9.9%), нечасто (0.1-0.9%), редко (0.01-0.09%), очень
          редко (&lt;0.01%).<sup>2</sup>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "Reactions",
  data: () => ({
    currentBlock: 4,
  }),
  computed: {
    blocks() {
      return [
        [
          {
            title: "Редко",
            description:
              "Нарушения зрения, сухость и/или раздражение глаз, конъюнктивит",
          },
        ],
        [
          {
            title: "Очень редко",
            description: "Звон в ушах, нарушения вкусовых ощущений",
          },
        ],
        [
          {
            title: "Часто",
            description: "Одышка при физической нагрузке",
          },
          {
            title: "Нечасто",
            description: "Бронхоспазм",
          },
          {
            title: "Редко",
            description: "Ринит",
          },
        ],
        [
          {
            title: "Часто",
            description:
              "Брадикардия, ортостатическая гипотензия, похолодание конечностей, ощущение сердцебиения",
          },
          {
            title: "Нечасто",
            description:
              "Временное усиление симптомов СН, АВ-блокада 1 степени, кардиогенный шок у пациентов с ОИМ, отеки, боль в области сердца",
          },
          {
            title: "Редко",
            description: "Другие нарушения проводимости, аритмии",
          },
          {
            title: "Очень редко",
            description:
              "Гангрена у пациентов с тяжелыми нарушениями периферического кровообращения",
          },
        ],
        [
          {
            title: "Часто",
            description: "Тошнота, боли в области живота, диарея, запор",
          },
          {
            title: "Нечасто",
            description: "Рвота",
          },
          {
            title: "Редко",
            description: "Сухость слизистой оболочки полости рта",
          },
        ],
        [
          {
            title: "Редко",
            description: "Сексуальная дисфункция",
          },
        ],
        [
          {
            title: "Очень редко",
            description: "Артралгия",
          },
        ],
        [
          {
            title: "Нечасто",
            description: "Кожная сыпь, повышенное потоотделение",
          },
          {
            title: "Редко",
            description: "Выпадение волос",
          },
          {
            title: "Очень редко",
            description: "Фотосенсибилизация, обострение псориаза",
          },
        ],
        [
          {
            title: "Нечасто",
            description: "Увеличение массы тела",
          },
        ],
        [
          {
            title: "Очень редко",
            description: "Тромбоцитопения",
          },
        ],
        [
          {
            title: "Редко",
            description: "Нарушения функции печени",
          },
          {
            title: "Очень редко",
            description: "Гепатит",
          },
        ],
        [
          {
            title: "Очень часто",
            description: "Повышенная утомляемость",
          },
          {
            title: "Часто",
            description: "Головокружение, головная боль",
          },
          {
            title: "Нечасто",
            description:
              "Парестезия, судороги, депрессия, снижение концентрации внимания, сонливость/бессонница, ночные кошмары",
          },
          {
            title: "Редко",
            description: "Повышенная нервная возбудимость, тревожность",
          },
          {
            title: "Очень редко",
            description:
              "Амнезия/нарушения памяти, подавленность, галлюцинации",
          },
        ],
      ];
    },
  },
};
</script>

<style lang="scss" scoped>
sup {
  vertical-align: super;
  font-family: inherit;
  font-style: normal;
  font-weight: inherit;
  font-size: smaller;
  line-height: 1;
  color: currentColor;
  white-space: nowrap;
}
.reactions-comp {
  max-width: 1000px;
  margin: 0 auto;
  &__description {
    margin-bottom: 15px;
    font-family: "Roboto", sans-serif;
    font-size: 18px;
    font-style: italic;
    font-weight: 400;
    line-height: 26px;
    text-align: center;
    color: #3c4242;

     @media screen and (max-width: 1219px) {
        font-size: 16px;
        line-height: 24px;
      }
  }

  &__container {
     @media screen and (max-width: 1219px) {
      display: flex;
      justify-content: flex-start;
      align-items: flex-start;
      column-gap: 5px;
     }
  }

  &__row {
    position: relative;
    z-index: 2;
    padding: 15px 58px;
    border-radius: 32px;
    border: 1px solid #d2d2d2;
    box-shadow: 0px 30px 50px -30px #00000059;
    display: grid;
    grid-template-columns: repeat(12, 1fr);
    column-gap: 20px;
    background-color: #f8f8f8;

    @media screen and (max-width: 1219px) {
     grid-template-columns: 100%;
     row-gap: 5px;
     padding: 10px;
     box-shadow: 0px 15px 20px -15px #00000059;
    }
  }

  &__icon {
    width: 56px;
    height: 56px;
    position: relative;
    cursor: pointer;

     @media screen and (max-width: 1219px) {
      width: 40px;
      height: 40px;
     }

    & img {
      position: absolute;
      top: 0;
      left: 0;
      width: 100%;
    }
  }

  &__body {
    position: relative;
    display: grid;
    grid-template-columns: 385px 385px;
    column-gap: 32px;
    justify-content: center;

     @media screen and (max-width: 1219px) {
      grid-template-columns: 100%;
      width: 100%;
     }
  }

  &__img {
    margin-top: 40px;
    @media screen and (max-width: 1219px) {
      position: absolute;
      width: 100%;
      z-index: -3;
      top: 110px;

      & img {
        width: 100%;
      }
    }
  }

  &__text {
    &-block {
      position: relative;
      padding: 60px 30px 30px;
      border-radius: 0 0 32px 32px;
      background-color: #f8f0f5;
      border: 1px solid #d2d2d2;
      height: fit-content;

       @media screen and (max-width: 1219px) {
        border-radius: 32px;
        padding: 25px;
       }
      &::before {
        position: absolute;
        bottom: -10px;
        left: 4px;
        width: calc(100% - 8px);
        height: 100%;
        border-radius: 32px;
        background-color: #e6ccdce5;
        content: "";
        display: block;
        z-index: -1;
      }
      &::after {
        position: absolute;
        bottom: -20px;
        left: 8px;
        width: calc(100% - 16px);
        height: 100%;
        border-radius: 32px;
        content: "";
        display: block;
        z-index: -2;
        background-color: #cc99b9;
      }
    }

    &-item {
      &:not(:last-child) {
        padding-bottom: 25px;
        margin-bottom: 25px;
        border-bottom: 1px solid #d2d2d2;
      }

      &-title {
        margin-bottom: 15px;
        font-family: "Roboto Slab", sans-serif;
        font-size: 23px;
        font-weight: 600;
        line-height: 28px;
        color: #830051;

        @media screen and (max-width: 1219px) {
          font-size: 18px;
          line-height: 22px;
        }
      }

      &-description {
        font-family: "Roboto", sans-serif;
        font-size: 18px;
        font-weight: 400;
        line-height: 26px;
        color: #3c4242;

        @media screen and (max-width: 1219px) {
          font-size: 16px;
          line-height: 22px;
        }
      }
    }
  }

  &__tip {
    width: 170px;
    position: absolute;
    right: 0;
    bottom: 0;
    font-family: "Roboto", sans-serif;
    font-size: 14px;
    font-weight: 400;
    line-height: 20px;
    text-align: left;
    color: #b2b4b4;
    transform: translateX(50%);

    @media screen and (max-width: 1219px) {
      margin-bottom: 15px;
          position: static;
          transform: none;
          width: auto;

        }
  }
}
</style>